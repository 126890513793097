// import react and gatsby libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as FormActions from '../Subscribe/formActions'
import queryString from 'query-string'

// Authorize Component
class Authorize extends Component {

  // check for a user in local storage first
  // and then scan url params to see if an email and token exists
  componentDidMount = () => {

    const {
      authorized,
      authorize,
      validate
    } = this.props

    //console.log(authorized)

    if ( !authorized.authorized ) {

      // attempt authorization by checking local storage first
      let user = JSON.parse(localStorage.getItem('how2charist-user'))
      //console.log(user)
      // if (user && user.authorized === 'Authorized') {
      //   authorize(user)
      // }
      if (user) {
        authorize(user)
      }

      //console.log(window.location.search)
      // second attempt using url params
      if (window.location.search !== '') {
        // get the params
        const params = queryString.parse(window.location.search)
        // build a check for both email and token
        const {
          email,
          token
        } = params
        // if both email and token exist then validate
        if (email && token) {
          validate(params)
        }
      }

    }

  }

  // this component is used for logic/state and
  // not rendering and visible content
  render = () => {
    return <div />
  }
}

// map state to props
const mapStateToProps = ({ authorized }) => {
  return { authorized }
}

// map dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    authorize: (user) => dispatch({ type: `AUTHORIZED`, user: user }),
    validate: (formData) => dispatch(FormActions.submitValidateForm(formData))
  }
}

// ConnectedAuthorize component
const ConnectedAuthorize = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Authorize)

export default ConnectedAuthorize
