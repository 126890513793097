// import react libs
import React from 'react'
import { connect } from 'react-redux'
import { StaticQuery, graphql } from 'gatsby'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons'

// styled WatchNow element
const WatchNow = styled.div`

  padding: 2rem 2rem 0;

  button {
    background: #76d4e5;
    color: #fff;
    font-size: 1.75rem;
    padding: 0.75rem 1.5rem;
    border-radius: 3px;
    border: 0;
    outline: 0;
    highlight: none;
    cursor: pointer;

    &:hover {
      background: #88e3f3;
    }

    span {
      display: inline-block;
      margin-right: 1rem;
    }

    svg {
      font-size: 1.5rem;
    }
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    margin-left: 2rem;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

// WatchNow component
const WatchNowButton = ({ authorized, openModal }) => {

  return(
    <StaticQuery
      query={watchNowQuery}
      render={data => {

        // user is logged in, start here
        if (!authorized.authorized) {
          return(
            <WatchNow
              onClick={() => openModal(data.intro)}>
              <button>
                <span>Watch the Intro</span>
                <FontAwesomeIcon icon={faPlay} />
              </button>
            </WatchNow>
          )
        }

        // user is not logged in
        return(
          <WatchNow
            onClick={() => openModal(data.chapter0)}>
            <button>
              <span>Watch the Intro</span>
              <FontAwesomeIcon icon={faPlay} />
            </button>
          </WatchNow>
        )

      }}
    />
  )
}

// WatchNow query
const watchNowQuery = graphql`
  query WatchNow {
    chapter0:markdownRemark(
      frontmatter:{
        title:{ eq:"Introduction" }
      }
    ) {
      ...Video
    }
    intro:markdownRemark(
      frontmatter:{
        title:{ eq:"Watch the Intro" }
      }
    ) {
      ...Video
    }
  }
  fragment Video on MarkdownRemark {
    id
    frontmatter {
      title
      subtitle
      wistia_id
      wistia_cc_id
    }
    html
  }
`

// map state to props
const mapStateToProps = ({
  authorized,
}) => {
  return {
    authorized,
  }
}

// map dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    openModal: (video) => {
      dispatch({ type: `OPEN_VIDEO_MODAL`, video })
    },
  }
}

// ConnectedWatchNow component
const ConnectedWatchNow = connect(
  mapStateToProps,
  mapDispatchToProps
)(WatchNowButton)

// export ConnectedWatchNow component
export default ConnectedWatchNow
