// import react and gatsby libs
import React from 'react'
import { connect } from 'react-redux'
import { StaticQuery, graphql } from 'gatsby'
import Link from 'gatsby-link'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// import other components
import VideoGrid from './VideoGrid'

// styled container element
const Container = styled.div`

  padding: 0;

  p.chapter-header {
    color: #fff;
    margin-left: 2rem;
    font-size: 1.25rem;
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    margin: 0 2rem;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

const Downloads = styled.ul`

  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    color: #fff;
    margin-right: 0.5rem;

    a {
      margin: 0;
    }
  }

  a.download-link {
    color: #fff;
    line-height: 2rem;
    display: block;
    text-decoration: none;

    background: #8cd2e2;
    color: #fff;
    display: inline-block;
    border-radius: 3px;
    padding: 0 0.5rem;
    cursor: pointer;
    margin-right: 0.5rem;

    &:hover {
      background: #76d4e5;
    }

    ${media.greaterThan("small")`
      /* screen width is greater than 450px (small) */

    `}

    ${media.greaterThan("medium")`
      /* screen width is greater than 768px (large) */

    `}
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    margin: 0 2rem;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}

`

// // styled Download element
// const Download = styled.a`
//   color: #fff;
//   line-height: 2rem;
//   display: block;
//   text-decoration: none;
//
//   span {
//     background: #444;
//     color: #fff;
//     display: inline-block;
//     border-radius: 3px;
//     padding: 0 0.5rem;
//     cursor: pointer;
//     margin-right: 0.5rem;
//
//     &:hover {
//       background: #76d4e5;
//     }
//   }
//
//   ${media.greaterThan("small")`
//     /* screen width is greater than 450px (small) */
//     margin: 0 2rem;
//   `}
//
//   ${media.greaterThan("medium")`
//     /* screen width is greater than 768px (large) */
//
//   `}
// `

// VideoNavigation compnent
const VideoNavigation = ({ authorized }) => {

  // not authorized so display an empty <div />
  if (!authorized.authorized) return <div />

  // let videoDownloadUrl = `https://episcopalchurch.wistia.com/medias/08iseetbll/download?media_file_id=348902207`

  return (
    <StaticQuery
      query={videosQuery}
      render={data => {
        // get the videos
        const videos = data.allMarkdownRemark.edges
        // render html after the query
        return(
          <Container>
            <p className="chapter-header">Watch each chapter</p>
            <VideoGrid
              videos={videos} />
            <Downloads className="downloads">
              <li><Link to="/downloads" className="download-link">Downloads</Link></li>
            </Downloads>
          </Container>
        )
      }}
    />
  )
}

// videos gql query
const videosQuery = graphql`
  query VideosQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "video" }
          grid: { eq: true }
        }
      }
      sort: { fields: frontmatter___title }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            wistia_id
            wistia_cc_id
            wistia_full_length_id
            wistia_full_length_cc_id
            timecode
            grid
          }
          html
        }
      }
    }
  }
`

// map state to props
const mapStateToProps = ({
  authorized,
}) => {
  return {
    authorized,
  }
}

// ConnectedVideoNavigation component
const ConnectedVideoNavigation = connect(
  mapStateToProps,
)(VideoNavigation)

// export ConnectedVideoNavigation component
export default ConnectedVideoNavigation
