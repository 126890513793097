// import react libs
import React from 'react'
import { connect } from 'react-redux'
import * as FormActions from './formActions'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// import components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
import Message from '../Message'

// styled Container element
const Container = styled.div`
  margin: 2rem 2rem 1rem;
  position: relative;
  overflow: hidden;
`

// styled Form element
const Form = styled.form`

  p {
    display: grid;
    grid-template-columns: 1fr 3rem;
    width: 100%;
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    max-width: 500px;
    margin-left: 2rem;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}

  .email,
  input,
  button {
    line-height: 1.5rem;
    display: block;
    box-display: border-box;
  }

  .email {
    background: #76d4e5;
    color: #fff;
    font-size: 1.25rem;
    width: 100%;
    padding: 0.7rem 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    &:hover {
      background: #88e3f3;
    }

    ${media.greaterThan("small")`
      /* screen width is greater than 450px (small) */
      font-size: 1.75rem;
    `}

    ${media.greaterThan("medium")`
      /* screen width is greater than 768px (large) */

    `}

  }

  input {
    background: rgba(255,255,255,0.35);
    outline: 0;
    border: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    font-size: 1rem;
    color: #fff;
    padding: 0 1rem;

    &::placeholder {
      color: #fff;
    }
  }

  button {
    background: none;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
`

// styled Label element
const Label  = styled.p`
  color: #fff;
  font-style: italic;
  font-size: 1rem;
  margin: 0.75rem 0;
  padding: 0;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    max-width: 500px;
    margin-left: 2rem;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

// styled Toggle element
const Toggle = styled.span`
  cursor: pointer;
  color: #8cd2e2;
`

// UserForm component
const UserForm = (props) => {

  const {
    authorized,
    form,
    submitForm,
    actionInvite,
    actionValidate,
    // actionReset,
    updateEmail,
    updateToken } = props

  if (form.isFetching) return (
    <div />
  )

  if (form.error) return (
    <div />
  )

  // user is authorized, hide the form
  if (authorized.authorized) return <div />

  // user succesfully requested an invite
  if (form.data.token !== undefined) {
    return(
      <Message cssClass="message">
        <p>We've received your request! You should receive a confirmation email within 10-15 minutes. (Be sure to check your spam/junk folders!)</p>
        <p>Trouble accessing the site? Send us an <a href="mailto:how2charist@gmail.com?subject='Site Login Troubleshooting'">email.</a></p>
      </Message>
    )
  }

  // set formData to pass on to submitForm action
  let formData = {
    email: form.email,
    token: form.token,
    action: form.action
  }

  // show the invite and access form
  return(
    <div>
      { form.action === 'invite' && (
        <Label>
          <span>The How2charist film and companion resources are completely free. Enter your email address to access these resources.</span><br /><br />
          {/*<Toggle
            onClick={actionValidate}>Already have an access code?</Toggle>*/}
        </Label>
      )}
      { form.action === 'validate' && (
        <Label>
          <span>Enter your email and access code. </span>
          <Toggle
            onClick={actionInvite}>Request an invite?</Toggle>
        </Label>
      )}
      { form.action === 'reset' && (
        <Label>
          <span>Enter your email for a new access code. </span>
          <Toggle
            onClick={actionInvite}>Request an invite instead?</Toggle>
        </Label>
      )}

      <Form
        onSubmit={(event) => submitForm(event, formData)}
        autoComplete="off">
        <p>
          <input
            name="email"
            type="email"
            placeholder="Your email"
            onChange={(event) => updateEmail(event.target.value)} />
          <button
            type="submit"
            className="email">
            <FontAwesomeIcon
              icon={faEnvelope} />
          </button>
        </p>
        { form.action === 'validate' && (
          <p>
            <input
              name="token"
              type="text"
              placeholder="Your access code"
              onChange={(event) => updateToken(event.target.value)} />
            <button
              type="submit"
              className="email">
              <FontAwesomeIcon
                icon={faLock} />
            </button>
          </p>
        )}
        {/* form.action === 'validate' && (
          <Toggle
            onClick={actionReset}>I lost my access code</Toggle>
        )*/}
      </Form>

    </div>
  )
}

// map state to props
const mapStateToProps = ({
  authorized,
  form
}) => {
  return {
    authorized,
    form
  }
}

// map dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    actionInvite: () => dispatch({ type: `ACTION_INVITE` }),
    actionValidate: () => dispatch({ type: `ACTION_VALIDATE` }),
    actionReset: () => dispatch({ type: `ACTION_RESET` }),
    submitForm: (e, formData) => {
      e.preventDefault()

      // invite action
      if (formData.action === 'invite') {
        console.log(formData)
        localStorage.setItem('how2charist-user', JSON.stringify(formData))
        dispatch(FormActions.submitInviteForm(formData))
        dispatch({ type: `AUTHORIZED`, user: formData })
      }

      // validate action
      if (formData.action === 'validate') {
        dispatch(FormActions.submitValidateForm(formData))
      }

      // reset action
      if (formData.action === 'reset') {
        dispatch(FormActions.submitResetForm(formData))
      }

    },
    updateEmail: (email) => dispatch({ type: `UPDATE_EMAIL`, email }),
    updateToken: (token) => dispatch({ type: `UPDATE_TOKEN`, token }),
  }
}

// ConnectedUserForm component
const ConnectedUserForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserForm)

// subscribe component
export default () => (
  <Container>
    <ConnectedUserForm />
  </Container>
)
