// import react libs
import React from 'react'
import { Link } from 'gatsby'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'
import LogoImage from '../assets/header.png'

// import components
import KH from './KH'

// styled html5 container element
const Container = styled.div`
  background: #222;
  overflow: hidden;
  padding: 2rem;
  text-align: center;

  p {
    line-height: 1rem;
    margin: 0.25rem 0;
  }

  a {
    color: #aaa;
    text-decoration: none;
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    padding: 2rem 4rem;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

const Copyright = styled.div`
  color: #555;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    text-align: right;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

const Logo = styled.img`
  width: 480px;
  height: auto;
  margin-bottom: 1rem;
`

// footer element
const Footer = () => (
  <Container>
    <Copyright>
      <p><Logo src={LogoImage} alt="#how2charist" /></p>
      <p>&copy; { new Date().getFullYear() } <a href="https://callieswanlund.com" target="_blank" rel="noopener noreferrer">The Rev. Callie Swanlund.</a>  All Rights Reserved.</p>
      <p>Created in partnership with <a href="https://episcopalchurch.org" target="_blank" rel="noopener noreferrer">The Episcopal Church</a> Digital Ministries.</p><br />
      <p><Link to="/terms">Terms</Link> / <Link to="/privacy">Privacy Policy</Link></p>
      <KH />
    </Copyright>
  </Container>
)

// export footer element
export default Footer
