// import react and gatsby libs
import React from "react"
//import { graphql } from 'gatsby'

// import css libs
import styled, { createGlobalStyle } from 'styled-components'

// import components
import Layout from '../components/Layout'
import Head from '../components/Head'
import Header from '../components/LandingHeader/Header'
import VideoMasthead from '../components/VideoMasthead'
import Branding from '../components/Branding'
//import Countdown from '../components/Countdown'
import WatchNow from '../components/WatchNow'
import Subscribe from '../components/Subscribe/Subscribe'
import VideoNavigation from '../components/VideoNavigation/VideoNavigation'
import VideoModal from '../components/VideoNavigation/VideoModal'
//import Invite from '../components/Invite'
//import Colophon from '../components/Colophon'
import Footer from '../components/Footer'

// apply some global styles to app
const GlobalStyle = createGlobalStyle`
  body {
    background: #222;
  }
`

// styled content element
const Content = styled.div`
  position: relative;
`

export default () => (
  <Layout>
    <GlobalStyle />
    <Head
      title="How2Charist: Digital Instructed Eucharist"
      description="Visit today and sign up to receive free access!" />
    <Content>
      <VideoMasthead />
      <Header />
      <Branding />
      <WatchNow />
      <Subscribe />
      {/*}<Countdown endtime="December 19 2018 18:00:00 EST" />*/}
    </Content>
    <VideoNavigation />
    {/* <Colophon /> */}
    <Footer />
    <VideoModal />
  </Layout>
)
