// import react and gatsby libs
import React from 'react'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// import other components
import Navbar from './Navbar'

// styled Header element
const Header = styled.header`
  overflow: hidden;
  padding: 2rem;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */

  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

// Header component
export default () => (
  <Header>
    <Navbar />
  </Header>
)
