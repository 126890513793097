// import react libs
import React from 'react'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// styled html5 branding wrapper
const Wrapper = styled.div`
  padding: 0 2rem 0;
  text-shadow: 1px 0px 2px rgba(0, 0, 0, 0.2);

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    margin-left: 2rem;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}

`

const Title = styled.h1`
  font-size: 2rem;
  line-height: 2.25rem;
  color: #fff;
  font-family: 'Arapey', serif;
  font-weight: 100;
  margin: 0;
  padding: 0;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    font-size: 3rem;
    line-height: 3.25rem;
    text-align: left;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */
    font-size: 4.25rem;
    line-height: 3.75rem;
  `}

  span {
    display: block;
  }
  span.white {
    color: #fff;
  }
  span.blue {
    color: #76d4e5;
  }
  span.pink {
    color: #fff;
    background: #f68588;
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    text-shadow: none;

    ${media.greaterThan("small")`
      /* screen width is greater than 450px (small) */
      font-size: 3rem;
      line-height: 3.25rem;
      text-align: left;
    `}

    ${media.greaterThan("medium")`
      /* screen width is greater than 768px (large) */
      font-size: 2.5rem;
      line-height: 2.75rem;
      padding: 0 1rem 0.25rem;
    `}
  }
`

const Description = styled.div`
  color: #fff;
  font-family: sans-serif;
  font-size: 1.2rem;
  line-height: 2rem;
  padding-top: 0.5rem;

  p {
    margin: 0.5rem 0 0;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    text-align: left;
    width: 65vw;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */
    width: 45vw;
  `}
`

// default component
const Branding = () => (
  <Wrapper>
    <Title>
      <span className="white">How2charist:</span>
      <span className="pink">Digital Instructed Eucharist</span>
    </Title>
    <Description>
      <p>Bread+wine: blessed. God: present. What's the meaning behind
      our words+rituals? How2charist illuminates the liturgy's beauty+spirit.</p>
    </Description>
  </Wrapper>
)

// export default component
export default Branding
