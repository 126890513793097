import axios from 'axios'


// request starting action
export let startRequest = () => {
  return {
    type: 'START_REQUEST'
  }
}

// request ended action
export let endRequest = (data) => {
  return {
    type: 'END_REQUEST',
    data: data
  }
}

// validate action ended
export let authorize = () => {
  return {
    type: 'AUTHORIZED'
  }
}

// there was an error
export let errorMessage = (error) => {
  return {
    type: 'FORM_ERROR',
    error: error
  }
}

// invite user action
export let submitInviteForm = (formData) => {
  return (dispatch) => {
    // inform redux we're validating
    dispatch(startRequest())

    // build search param
    let params = `?email=${formData.email}`
    //let url = `/.netlify/functions/generateToken${params}`
    let url = `/.netlify/functions/generateAutomatedToken${params}`
    // generate a subscriber and token
    axios.get(url)
      .then(results => {
        dispatch(endRequest(results.data))
      })
      .catch(error => {
        dispatch(errorMessage('Something went wrong. Have you already signed up?'))
      })
  }
}

// validate user action
export let submitValidateForm = (formData) => {

  return (dispatch) => {
    // inform redux we're validating
    dispatch(startRequest())

    // build search param
    let params = `?email=${formData.email}&token=${formData.token}`
    let url = `/.netlify/functions/validateToken${params}`

    // validate a subscriber token
    axios.get(url)
      .then(response => {

        // user is authorized
        if(response.data.authorized === 'Authorized' && response.status === 200) {

          let baseUrl = window.location.href
          let search = window.location.search
          let newUrl = baseUrl.replace(search, '')
          window.history.pushState({}, null, newUrl);

          // set the user in local storage
          localStorage.setItem('how2charist-user', JSON.stringify(response.data))
          dispatch(endRequest(response.data))
          dispatch(authorize())
        }
        // user is not authorized
        else {
          dispatch(errorMessage('The email or access code you supplied is invalid.'))
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch(errorMessage('An error has occurred. Please try again.'))
      })
  }
}

// reset user action
export let submitResetForm = (formData) => {
  return (dispatch) => {
    // inform redux we're validating
    dispatch(startRequest())

    // build search param
    let params = `?email=${formData.email}`
    let url = `/.netlify/functions/resendToken${params}`
    // generate a subscriber and token
    axios.get(url)
      .then(results => {
        dispatch(endRequest(results.data))
      })
      .catch(error => {
        dispatch(errorMessage('Something went wrong. Have you received an invitation yet?'))
      })
  }
}
