import React from 'react'

import styled from 'styled-components'

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70vh;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  background: #111;

  &:after {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(to top,
       rgba(34,34,34, 1) 1%,
       rgba(34,34,34, 0) 90%
    );
    pointer-events: none; /* so the text is still selectable */
  }
`

const Iframe = styled.iframe`
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const vimeoUrl = 'https://fast.wistia.net/embed/iframe/tr8tpvwo2b?muted=true&autoPlay=true'

const VideoMasthead = ({ children }) => (
  <VideoWrapper>
      <Iframe
        src={`${vimeoUrl}?background=1&autoplay=1&loop=1&byline=0&title=0&portrait=0`}
        frameBorder="0"
        webkitAllowFullScreen
        mozAllowFullScreen
        allowFullScreen
        />
  </VideoWrapper>
)

export default VideoMasthead
