// import gatsby and react libs
import React from 'react'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// import other components
import VideoNavigationItem from './VideoNavigationItem'

// styled videos element
const Videos = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  margin: 0 2rem 2rem;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1rem;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`

// export default VideoGrid
export default ({ videos, toggleVideo, closeVideoModal }) => (
  <Videos>
    {videos.map(video => (
      <VideoNavigationItem
        key={video.node.id}
        video={video} />
    ))}
  </Videos>
)
