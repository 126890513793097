// import react libs
import React from 'react'
import { connect } from 'react-redux'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// import components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

// styled MessageBox element
const MessageBox = styled.div`
  padding: 1rem 2rem;
  box-sizing: border-box;
  border-radius: 3px;
  background: #f7f7f7;
  color: #222;
  position: relative;
  font-size: 1.15rem;

  &.message {
    background: #8cd2e2;
    color: #222;
  }

  &.error {
    background: #e78b8b;
    color: #fff;
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    max-width: 500px;
    margin-left: 2rem;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}

`

// styled CloseButton element
const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1.5rem;
  cursor: pointer;
`

// export Message component
const Message = ({ children, cssClass, closeMessage }) => (
  <MessageBox className={cssClass}>
    {children}
    <CloseButton
      onClick={() => closeMessage()}>
      <FontAwesomeIcon
      icon={faTimesCircle}
      className="email" />
    </CloseButton>
  </MessageBox>
)

// map state to props
const mapStateToProps = () => {
  return {}
}

// map dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    closeMessage: () => dispatch({ type: `CLOSE_MESSAGE` }),
  }
}

// ConnectedMessage component
const ConnectedMessage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Message)

// export ConnectedMessage component
export default ConnectedMessage
