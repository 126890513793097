// import gatsby and react libs
import React from 'react'
import { connect } from 'react-redux'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// import other components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import EmbedContainer from './EmbedContainer'

// styled modal component
const Modal = styled.div`

  display: none;
  position: fixed;
  background: rgb(0,0,0);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.active {
    display: block;
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */

  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

// styled close button
const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
`

// styled wrapper element
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
`

// styled Content element
const Content = styled.div`
  color: #fff;
  text-align: center;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */

  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */
    width: 60vw;
  `}

  button {
    background: #efefef;
    color: #aaa;
    border: 0;
    outline: 0;
    highlight: none;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background: #76d4e5;
      color: #fff;
    }

    &.active {
      background: #76d4e5;
      color: #fff;

      &:hover {
        background: #88e3f3;
      }
    }
  }
`

// export VideoModal component
const VideoModal = ({ videoNavigation, closeModal, toggleCC }) => {

  const {
    video,
    videoModalOpen
  } = videoNavigation

  // set the active css class
  let cssClass = videoModalOpen ? 'active' : 'inactive'

  // no video so return an empty <div />
  if (!video) return <div />

  let baseUrl = `https://fast.wistia.net/embed/iframe/`
  let params = `?seo=false&videoFoam=false&fullscreenButton=true`

  // get the normal video id
  let videoId = video.frontmatter.wistia_id

  // get the normal video cc id
  if (videoNavigation.ccEnabled) {
    videoId = video.frontmatter.wistia_cc_id
  }

  // get the full length film id because this is grid
  if (video.frontmatter.grid) {
    videoId = video.frontmatter.wistia_full_length_id
  }

  // get the full length film cc id because this is grid and cc
  if (video.frontmatter.grid && videoNavigation.ccEnabled) {
    videoId = video.frontmatter.wistia_full_length_cc_id
  }

  // build the video url
  let videoUrl = `${baseUrl}${videoId}${params}`

  // if timecode exists add it as a param
  if (video.frontmatter.timecode) {
    videoUrl = `${videoUrl}&time=${video.frontmatter.timecode}`
  }

  console.log(videoUrl)

  let activeClass = videoNavigation.ccEnabled ? 'active' : 'inactive'

  return(
    <Modal className={cssClass}>
      <CloseButton
        onClick={() => closeModal()}>
        <FontAwesomeIcon icon={faTimesCircle} />
      </CloseButton>
      <Wrapper>
        <Content>
          <h1>{video.frontmatter.title} &mdash; {video.frontmatter.subtitle}</h1>
          <EmbedContainer>
            <iframe
              src={videoUrl}
              title="Wistia video player"
              allowtransparency="true"
              allowfullscreen="true"
              frameBorder="0"
              scrolling="no"
              width="100%"
              height="100%" />
          </EmbedContainer>
          <button
            onClick={() => toggleCC()}
            className={activeClass}>CC</button>
        </Content>
      </Wrapper>
    </Modal>
  )
}

// map state to props
const mapStateToProps = ({ videoNavigation }) => {
  return { videoNavigation }
}

// map dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch({ type: `CLOSE_VIDEO_MODAL` }),
    toggleCC: () => dispatch({ type: `TOGGLE_VIDEO_CC` })
  }
}

// ConnectedVideoModal component
const ConnectedVideoModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoModal)

// export default component
export default ConnectedVideoModal
