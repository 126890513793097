// import react
import React from 'react'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// KH element
const KH = styled.div`
  margin-top: 1rem;

  a {
    color: #777;
    background: #444;
    display: inline-block;
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    font-family: sans-serif;
    font-size: 0.85rem;
    transform: translateZ(0);
    transition: all 0.3s ease;
    position: relative;
    &:hover {
      color: #fff;
    }
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #e14742;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition-property: transform;
      transition: 300ms ease-out;
    }
    &:hover:before {
      transform: scaleX(1);
    }

  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */

  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}

`

// KH component
export default () => (
  <KH>
    <a
      href="https://heitzman.co"
      target="_blank"
      rel="noopener noreferrer">
      KH
    </a>
  </KH>
)
