// import react and gatsby libs
import React from 'react'
import { Helmet } from 'react-helmet'

// import other components
import Authorize from './Authorize/Authorize'

// import css libs
import 'normalize.css'
import styled, { createGlobalStyle } from 'styled-components'
import media from 'styled-media-query'
import Loader from '../assets/loader.svg'

// apply some global styles to app
const GlobalStyle = createGlobalStyle`

  // .wf-loading * {
  //
  //   /*
  //   first things first, we need to hide everything,
  //   but bear in mind that this will only take effect
  //   once the loading script has taken effect.
  //   here we're hiding all content within
  //   the <html> once it has the class "wf-loading"
  //   */
  //   opacity: 0;
  //   background-image: url(${Loader});
  //   background-position: center;
  //   background-repeat: no-repeat;
  //
  //   /*
  //   just to make sure the <html>
  //   element shares the same dimensions as
  //   the browser window when loading
  //   (and not the potentially elongated page)
  //   */
  //   height: 100vh;
  //   overflow: hidden;
  // }
  //
  // .wf-active *,
  // .wf-inactive * {
  //     /*
  //     add some transitional effects
  //     to reintroduce the content
  //     gradually once fonts are loaded
  //     */
  //     -webkit-transition: opacity 100ms ease-out;
  //     -moz-transition: opacity 100ms ease-out;
  //     -o-transition: opacity 100ms ease-out;
  //     transition: opacity 100ms ease-out;
  // }

  @import url("https://use.fontawesome.com/releases/v5.6.0/css/svg-with-js.css");

  body {
    font-family: "Nunito Sans", sans-serif;
  }
`

// styled layout element
const Layout = styled.div`

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */

  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

// layout component
export default ({ children }) => {

  return (
    <Layout>
      <Helmet>
        <title>How2charist: Digital Instructed Eucharist</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" / >
      </Helmet>
      <GlobalStyle />
      <Authorize />
      {children}
    </Layout>
  )

}
