// import gatsby and react libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// import other components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

// styled videoitem element
const VideoItem = styled.div`
  background-color: #222;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */

  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

// styled videotitle element
const VideoTitle = styled.div`

  h3 {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }

  p {
    color: #fff;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    margin: 1rem 0 0 1rem;
  `}

  ${media.greaterThan(769)`
    /* screen width is greater than 768px (large) */
    margin: 2rem 0 0 1rem;
  `}
`

// styled playbutton
const PlayButton = styled.div`
  width: 5rem;
  height: 3rem;
  color: #fff;
  background: rgba(255,255,255,0.25);
  border-radius: 3px;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    margin: 1rem 0 1rem 1rem;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

// styled timecode element
const Timecode = styled.div`
  color: #fff;
  text-align: right;
  font-weight: 600;
`

const VideoThumbnailOverlay = styled.div`
  background: linear-gradient(to bottom,
     rgba(17,17,17, 0.5) 1%,
     rgba(17,17,17, 0) 90%
  );
  overflow: hidden;
  padding: 1rem;
  transition: all 100ms ease-out;

  &:hover {
    background: none;
  }
`

// VideoNavigationItem component
class VideoNavigationItem extends Component {

  state = {
    thumbnail: "",
    length: ""
  }

  pad = (number, length) => {

    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }

    return str;

  }

  componentDidMount() {

    const { video } = this.props

    let mediaHashedId = video.node.frontmatter.wistia_id
    let baseUrl = "https://fast.wistia.com/oembed/?url="
    let accountUrl = encodeURIComponent("https://episcopalchurch.wistia.com/medias/")
    let url = baseUrl + accountUrl + mediaHashedId + "&format=json&callback=?"

    // get wistia video data from the data api
    axios.get(url)
      .then(response => {
        let duration = response.data.duration
        let thumbnail = response.data.thumbnail_url
        let minutes = Math.floor(duration / 60)
        let seconds = Math.floor(duration - minutes * 60)

        this.setState({
          thumbnail: thumbnail,
          length: `${minutes}:${this.pad(seconds, 2)}`
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    const { video, openModal } = this.props

    return (
      <VideoItem
        // style={{ backgroundImage: `url(${video.node.frontmatter.video_thumbnail})` }}
        style={{ backgroundImage: `url(${this.state.thumbnail})` }}
        onClick={() => openModal(video.node)}>
        <VideoThumbnailOverlay>
          <VideoTitle>
            <h3>{video.node.frontmatter.title}</h3>
            <p>{video.node.frontmatter.subtitle}</p>
          </VideoTitle>
          <PlayButton>
            <FontAwesomeIcon icon={faPlay} />
          </PlayButton>
          <Timecode>
            <span>{this.state.length}</span>
          </Timecode>
        </VideoThumbnailOverlay>
      </VideoItem>
    )
  }
}

// map state to props
const mapStateToProps = ({ videoNavigation }) => {
  return { videoNavigation }
}

// map dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    openModal: (video) => dispatch({ type: `OPEN_VIDEO_MODAL`, video }),
  }
}

// ConnectedVideoNavigationItem component
const ConnectedVideoNavigationItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoNavigationItem)

// export default component
export default ConnectedVideoNavigationItem
