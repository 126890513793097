// import react and gatsby libs
import React from 'react'
import Helmet from 'react-helmet'

// import assets
import socialShareImage from '../assets/How2Social.png'

// exort Head component
export default (props) => {

  const {
    title,
    description,
  } = props

  return(
    <Helmet>
      <title>{title}</title>

      {/*  Essential META Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={socialShareImage} />
      <meta property="og:url" content="https://how2charist.com" />
      <meta name="twitter:card" content="summary_large_image" />

      {/*  Non-Essential, But Recommended */}
      <meta property="og:site_name" content={title} />
      <meta name="twitter:image:alt" content={title} />

      {/*  Non-Essential, But Required for Analytics */}
      {/* this can be handled by google tag manager I think
        <meta property="fb:app_id" content="your_app_id" /> */}
      <meta name="twitter:site" content="@callieswanlund" />
    </Helmet>
  )
}
