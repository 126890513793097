// import react
import React from 'react'
import { Link } from 'gatsby'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// import components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faInstagram } from '@fortawesome/free-brands-svg-icons';

// styled html5 social nav
const Navigation = styled.nav`

  ul {
    padding: 0;
    margin: 0;

    li {

      display: inline-block;
      margin: 0.25rem;
      color: #f7f7f7;

      a {
        display: block;
        transition: all 0.1s ease;
        color: #fff;
        text-decoration: none;

        &:hover {
          color: #88e3f3;
        }
      }

      a.socialLink {
        color: #222;
        background: rgba(255,255,255,0.5);
        line-height: 2rem;
        width: 2rem;
        border-radius: 2rem;
        text-align: center;
        font-size: 1rem;

        &:hover {
          background: #fff;
          color: #222;
        }
      }

      a.spacer {
        margin-left: 1rem;
      }
    }
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    float: right;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}

`

// styled Navbar element
const Navbar = () => (
  <Navigation>
    <ul>
      <li><Link to="/about">About How2charist</Link></li>
      <li>|</li>
      <li><Link to="/partners">Partner Backers</Link></li>
      <li>|</li>
      <li><Link to="/contact">Contact</Link></li>
      <li>
        <span>
          <a
            className="socialLink spacer"
            href="https://facebook.com/how2charist"
            target="_blank"
            rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </span>
      </li>
      <li>
        <span>
          <a
            className="socialLink"
            href="https://twitter.com/callieswanlund"
            target="_blank"
            rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </span>
      </li>
      <li>
        <span>
          <a
            className="socialLink"
            href="https://instagram.com/callieswanlund"
            target="_blank"
            rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </span>
      </li>
    </ul>
  </Navigation>
)

export default Navbar
