// import gatsby and react libs
import React from 'react'

// import css libs
import styled from 'styled-components'

// styled embed container element
const EmbedContainer = styled.div `

  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;

  iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }

`

// export embed container component
export default ({ children }) => (
  <EmbedContainer>
    {children}
  </EmbedContainer>
)
